import React from 'react';
import Landing from '../components/Landing';
import EmailForm from '../components/EmailForm';
const md = require('markdown-it')({ html: true });
const dedent = require('dedent-js');

const content = md.render(dedent`# Nous créons votre MVP gratuitement !

Votre projet nécessite un site Web ou une application Mobile à (faire) développer ? Vous n'avez ni les moyens financiers, ni les compétences techniques pour accomplir cette lourde tâche ? Le Reacteur aide les startupers motivés à franchir le cap, ça tombe bien !

Mais au fait, savez-vous ce qu'est un **MVP** (Minimum Viable Product) ? [Lisez bien cet article](https://www.lereacteur.io/blog/comment-creer-application-mobile), si c'est encore un peu flou pour vous.

## Nos élèves peuvent développer votre site Web ou votre application Mobile

Au cours de leur [formation de développeur](https://www.lereacteur.io/bootcamp-javascript/formation-developpeur-Web-Mobile/), nos élèves apprenent à coder des sites Web et des applications Mobile grâce au langage JavaScript, mais aussi à React et React Native. Suite à leur parcours, ils sont capables de mettre leurs compétences à profit, afin de développer un MVP.

Pendant les deux dernières semaines de la formation, nous mettons donc nos élèves en lien avec des porteurs de projets, afin de réaliser soit leur site Web, soit leur application Mobile.

À la fin, vous repartirez avec tout votre projet, vous n'aurez rien à nous payer. Vous serez propriétaire de tout le code source et les élèves signeront un accord de confidentialité qui ne leur permettra pas d'exploiter le projet directement, mais simplement pouvoir montrer leur travail à de futurs recruteurs.

## Ce qu'il faut savoir, avant de participer à l'appel à projets

- Vous devez être conscient qu'un appel à projets <u>**ne garantit pas**</u> que vous soyez sélectionné, même si vous pensez que votre projet est vraiment idéal. Retenez bien bien que ce sont les élèves qui (vous) choisiront, pas nous (l'équipe enseignante).
- Vous devez être conscient qu'un MVP ne constitue pas un produit à l'état final.
- Nous attendons de vous que vous soyez disponible : si nos élèves vous choisissent, vous allez devoir répondre (de manière réactive) à leurs sollicitations pendant toute la durée du développement (2 semaines).
- Vous devrez être disponible sur Zoom, pendant le jour des pitchs et du [Demo Day](https://www.lereacteur.io/bootcamp-javascript/formation-developpeur-web-mobile#projets) (qui a lieu dans nos locaux, à Paris); les dates vous seront communiquées plus tard.

## Voici les projets que nous refusons

- Ceux pour lesquels il n'y a aucune maquette ou qui ont des maquettes non-abouties : nos élèves ne les feront pas à votre place ; ils sont venus chez nous pour apprendre la programmation, pas le design.
- Ceux qui ne sont pas en lien direct avec le porteur : si vous cherchez de la main d'œuvre gratuite pour que nos élèves travaillent sur les projets de vos clients, passez votre chemin.
- Ceux qui sont à "reprendre" pour continuer leur développement : nos élèves ne partiront d'aucun code existant mais d'une feuille blanche.
- Ceux auxquels vous souhaitez imposer une techno particulière : nos élèves apprennent à coder en JavaScript et ils utilisent Node.js côté backend, ainsi que React (pour le Web) et [React Native](https://www.lereacteur.io/blog/pourquoi-react-native-est-le-bon-choix-pour-creer-votre-mvp/) (pour le Mobile) côté frontend. Ils n'utiliseront aucune autre techno comme : Angular, Dart, Flutter, Wordpress, etc.

## Êtes-vous prêt à vous lancer ?

Si vous remplissez tous les critères pour postuler à cet appel à projets, alors il vous suffit de nous laisser vos coordonnées, pour recevoir le lien vers le formulaire dédié.

Si vous pensez que vous n'êtes pas encore opérationnel (notamment sur les maquettes, par exemple, ou parce que votre idée est encore un peu floue), <u>rien ne sert de vous précipiter</u>. Revenez quand vous serez prêt, vous aurez beaucoup plus de chance d'être sélectionné.

Toute l'équipe du Reacteur vous souhaite bonne chance !
`);

const Page = React.memo(({ location }) => {
  return (
    <Landing
      location={location}
      content={[{ type: 'md', content }]}
      pageName="Nous créons votre MVP (Minimum Viable Product) gratuitement"
      metaTitle="Nous créons votre MVP (Minimum Viable Product) gratuitement | Le Reacteur"
    >
      <EmailForm
        location={location}
        route="/api/mvp/send-form"
        submitButtonDefaultText="Recevoir l'appel à projet par mail"
      />
    </Landing>
  );
});

export default Page;
